import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import { useState, useRef, useEffect } from 'react';
import './index.css';
import { Link } from 'react-router-dom';

export default function VerificationCodeConfirmation({
  length = 6,
  email = '',
  onSubmit,
  onBack,
}) {
  const [code, setCode] = useState([]);
  const inputRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const stopEditRef = useRef(false);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!stopEditRef.current) {
        if (+event.key >= 0 && +event.key <= 9 && code.length <= length) {
          setCode((prevCode) => {
            const newCode = [...prevCode, event.key];
            return newCode;
          });
        } else if (event.key === 'Backspace') {
          setCode((prevCode) => {
            const newCode = [...prevCode];
            newCode.pop();
            return newCode;
          });
        }
      }
    };

    const handlePaste = (event) => {
      console.log('Paste event detected!');
      // Access pasted content
      const pastedData = event.clipboardData.getData('text');
      if (/^\d+$/.test(pastedData) && pastedData.length <= length) {
        setCode(pastedData.split(''));
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('past', handlePaste);
    };
  }, [length]);

  useEffect(() => {
    if (code.length === length) {
      stopEditRef.current = true;
      onSubmit(code.join(''), email)
        .catch(() => {
          setIsLoading(false);
          setCode([]);
        })
        .finally(() => {
          stopEditRef.current = false;
        });
      setIsLoading(true);
    }
    document.getElementById(`input-${code.length}`)?.focus();
  }, [code]);

  return (
    <div style={{ position: 'relative' }} className="verification-container">
      <p className="message">We have sent a code to {email}. Enter it below</p>
      <div style={{ position: 'relative' }}>
        <table style={isLoading ? { opacity: 0.4 } : {}}>
          <tbody>
            <tr>
              {new Array(6).fill('').map((_, index) => (
                <td
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  style={{
                    border:
                      code.length === index
                        ? '3px solid rgba(36, 103, 235, 0.527)'
                        : '1px solid #ccc',
                    width: '50px',
                    height: '40px',
                    textAlign: 'center',
                    fontSize: '1.2rem',
                    verticalAlign: 'middle',
                    left: `${code.length * 50}px`,
                  }}
                >
                  {/* <span
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    {index <= code.length ? code[index] : ''}
                  </span> */}
                  <input
                    id={`input-${index}`}
                    type="number"
                    style={{
                      width: '40px',
                      border: 'none',
                      outline: 'none',
                      textAlign: 'center',
                    }}
                    value={index < code.length ? code[index] : ''}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        {isLoading && (
          <div
            style={{
              position: 'absolute',
              width: '50px',
              height: '40px',
              top: 0,
              left: '125px',
            }}
          >
            <IBLSpinLoader />
          </div>
        )}
      </div>
      {!isLoading && (
        <p className="back" onClick={onBack}>
          <svg
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            fill="#2467eba3"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"></path>
              <path d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"></path>
            </g>
          </svg>{' '}
          Back
        </p>
      )}
    </div>
  );
}
