import { clearCookies } from '@iblai/ibl-web-react-common';

export function areSubdomainsOfSameParentDomain(domain1, domain2) {
  if (domain1 === domain2) {
    return true;
  }
  // Split the domains into their parts
  const parts1 = domain1.split('.').reverse();
  const parts2 = domain2.split('.').reverse();

  // Ensure both domains have at least two parts (e.g., "domain.com")
  if (parts1.length < 2 || parts2.length < 2) {
    return false;
  }

  return (
    parts1.slice(0, 2).reverse().join('.') ===
    parts2.slice(0, 2).reverse().join('.')
  );
}

export function getParentDomain(domain) {
  if (!domain) {
    return '';
  }
  const parts = domain.split('.');
  return parts.length > 1
    ? `.${parts.slice(-1 * (parts.length - 1)).join('.')}`
    : domain;
}

export function setCommonCookie(key, value) {
  document.cookie = `${key}=; Max-Age=0; path=/; Domain=${getParentDomain(
    window.location.hostname
  )}; secure; SameSite=None`;
  document.cookie = `${key}=${value}; domain=${getParentDomain(
    window.location.hostname
  )}; path=/; secure; SameSite=None`;
}

export const handleLogout = () => {
  const logoutRequests = [
    fetch(`${process.env.REACT_APP_IBL_LMS_URL}/logout`, {
      method: 'GET',
      credentials: 'include',
    }),
    fetch(`${process.env.REACT_APP_IBL_STUDIO_URL}/logout`, {
      method: 'GET',
      credentials: 'include',
    }),
  ];
  if (process.env.REACT_APP_EXTERNAL_IDP_LOGOUT_URL) {
    logoutRequests.push(
      fetch(`${process.env.REACT_APP_EXTERNAL_IDP_LOGOUT_URL}`, {
        method: 'GET',
        credentials: 'include',
      })
    );
  }
  localStorage.clear();
  const visited = document.cookie.includes('visited=true');
  clearCookies();
  if (visited) {
    setVisitedCookie();
  }
  Promise.allSettled([logoutRequests]).then(() => {});
};

export const verifyTokenOrCode = async (
  codeOrToken,
  type = 'code',
  email = ''
) => {
  const response = await fetch(
    `${process.env.REACT_APP_IBL_LMS_URL}/ibl-auth/${
      type === 'code' ? 'verify-code/?code=' : 'verify-token/?token='
    }${codeOrToken}&client_id=${process.env.REACT_APP_IBL_EDX_SSO_CLIENT_ID}${
      email ? '&email=' + email : ''
    }`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  if (response.ok) {
    if (process.env.REACT_APP_IBL_AUTH_EDX_USE_JWT === 'true') {
      const data = await response.json(); // Extract the JSON response
      const parentDomain = document.domain.split('.').slice(-2).join('.');
      document.cookie = `edx-jwt-cookie-header-payload=${data.token}; SameSite=None; Secure; Domain=${parentDomain};`;
      localStorage.setItem('edx_jwt_token', data.token); // Save the token in local storage
    }
    window.location.href = '/login/complete';
  } else {
    throw new Error('Error verifying token');
  }
};

export const requestVerificationCodeOrToken = async (email, redirectUri) => {
  const clientId = process.env.REACT_APP_IBL_EDX_SSO_CLIENT_ID;
  const requestUrl = `${
    process.env.REACT_APP_IBL_LMS_URL
  }/ibl-auth/request-link/?email=${encodeURIComponent(
    email
  )}&client_id=${clientId}&redirect_uri=${redirectUri}`;

  await fetch(requestUrl, {
    method: 'GET',
    credentials: 'include',
  }).then((response) => {
    if (!response.ok) {
      throw new Error('Failed to request verification code');
    }
    return response.json();
  });
};

export const setVisitedCookie = () => {
  const visitedCookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith('visited='));
  if (!visitedCookie) {
    // Set a cookie to indicate the user has visited the site
    document.cookie =
      'visited=true; path=/; max-age=31536000; SameSite=None; Secure'; // 1 year
  }
};

export const getTenantMetadata = async (tenantKey) => {
  return await fetch(
    `${process.env.REACT_APP_IBL_DM_URL}/api/core/orgs/${tenantKey}/metadata/`
  ).then((response) => response.json());
};
